// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  ActivityFeedbackDataRepresentation,
  ActivityResultDataRepresentationBaseApiListResult,
  ActivityResultDataRepresentationBaseApiResult,
  ActivityResultRepresentationBaseApiResult,
  ActivityResultSnapshotCompositeRepresentation,
  ActivityResultViewModel,
  CreateActivityFeedbackViewModel,
  GetStudentResultTotalsRepresentationBaseApiResult,
  LatestSubmittedSnapshotFeedbackRepresentation,
  LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult,
  SubmissionCoinsRepresentation,
  UpdateActivityResultFinishRepresentation,
} from '../models/index'
import {
  ActivityFeedbackDataRepresentationFromJSON,
  ActivityFeedbackDataRepresentationToJSON,
  ActivityResultDataRepresentationBaseApiListResultFromJSON,
  ActivityResultDataRepresentationBaseApiListResultToJSON,
  ActivityResultDataRepresentationBaseApiResultFromJSON,
  ActivityResultDataRepresentationBaseApiResultToJSON,
  ActivityResultRepresentationBaseApiResultFromJSON,
  ActivityResultRepresentationBaseApiResultToJSON,
  ActivityResultSnapshotCompositeRepresentationFromJSON,
  ActivityResultSnapshotCompositeRepresentationToJSON,
  ActivityResultViewModelFromJSON,
  ActivityResultViewModelToJSON,
  CreateActivityFeedbackViewModelFromJSON,
  CreateActivityFeedbackViewModelToJSON,
  GetStudentResultTotalsRepresentationBaseApiResultFromJSON,
  GetStudentResultTotalsRepresentationBaseApiResultToJSON,
  LatestSubmittedSnapshotFeedbackRepresentationFromJSON,
  LatestSubmittedSnapshotFeedbackRepresentationToJSON,
  LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResultFromJSON,
  LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResultToJSON,
  SubmissionCoinsRepresentationFromJSON,
  SubmissionCoinsRepresentationToJSON,
  UpdateActivityResultFinishRepresentationFromJSON,
  UpdateActivityResultFinishRepresentationToJSON,
} from '../models/index'

export interface ApiV1EwiStudentIdLatestSubmittedSnapshotsGetRequest {
  studentId: string
}

export interface ApiV1EwiStudentIdResultsActivityResultIdFeedbacksGetRequest {
  studentId: string
  activityResultId: string
}

export interface ApiV1EwiStudentIdResultsActivityResultIdFinishLaterPutRequest {
  studentId: string
  activityResultId: string
  activityResultViewModel?: ActivityResultViewModel
}

export interface ApiV1EwiStudentIdResultsActivityResultIdFinishPutRequest {
  studentId: string
  activityResultId: string
  activityResultViewModel?: ActivityResultViewModel
}

export interface ApiV1EwiStudentIdResultsActivityResultIdGetRequest {
  studentId: string
  activityResultId: string
  includeActivity?: boolean
  includeAnswers?: boolean
  includeFeedbacks?: boolean
  includeUsers?: boolean
}

export interface ApiV1EwiStudentIdResultsActivityResultIdRevisePutRequest {
  studentId: string
  activityResultId: string
}

export interface ApiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdFeedbacksPostRequest {
  studentId: string
  activityResultId: string
  activityResultSnapshotId: string
  createActivityFeedbackViewModel?: CreateActivityFeedbackViewModel
}

export interface ApiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdSubmitPutRequest {
  studentId: string
  activityResultId: string
  activityResultSnapshotId: string
  reflection?: string
}

export interface ApiV1EwiStudentIdResultsGetRequest {
  studentId: string
  activityId?: string
  activityIds?: Array<string>
  assignmentId?: string
  assignmentTaskId?: number
  status?: string
  grade?: string
  fromDate?: number
  toDate?: number
  skip?: number
  take?: number
  includeActivity?: boolean
  includeAnswers?: boolean
  includeFeedback?: boolean
  isQuest?: boolean
}

export interface ApiV1EwiStudentIdResultsPutRequest {
  studentId: string
  activityResultViewModel?: ActivityResultViewModel
}

export interface ApiV1EwiStudentIdResultsSummaryGetRequest {
  studentId: string
}

export interface ApiV1EwiStudentIdSnapshotsActivityResultSnapshotIdGetRequest {
  studentId: string
  activityResultSnapshotId: string
}

export interface ApiV1EwiStudentIdSubmittedSnapshotsGetRequest {
  studentId: string
  skip?: number
  take?: number
}

/**
 *
 */
export class EWIApi extends runtime.BaseAPI {
  /**
   * OBSOLETE: Use EWI GET/submitted_snapshots (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdLatestSubmittedSnapshotsGetRaw(
    requestParameters: ApiV1EwiStudentIdLatestSubmittedSnapshotsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<Array<LatestSubmittedSnapshotFeedbackRepresentation>>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdLatestSubmittedSnapshotsGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/latest_submitted_snapshots`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(LatestSubmittedSnapshotFeedbackRepresentationFromJSON),
    )
  }

  /**
   * OBSOLETE: Use EWI GET/submitted_snapshots (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdLatestSubmittedSnapshotsGet(
    requestParameters: ApiV1EwiStudentIdLatestSubmittedSnapshotsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<LatestSubmittedSnapshotFeedbackRepresentation>> {
    const response = await this.apiV1EwiStudentIdLatestSubmittedSnapshotsGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Get Activity Result Feedbacks (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdFeedbacksGetRaw(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdFeedbacksGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ActivityFeedbackDataRepresentation>>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdFeedbacksGet.',
      )
    }

    if (
      requestParameters.activityResultId === null ||
      requestParameters.activityResultId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityResultId',
        'Required parameter requestParameters.activityResultId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdFeedbacksGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/results/{activityResultId}/feedbacks`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityResultId'}}`,
            encodeURIComponent(String(requestParameters.activityResultId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ActivityFeedbackDataRepresentationFromJSON),
    )
  }

  /**
   * Get Activity Result Feedbacks (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdFeedbacksGet(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdFeedbacksGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ActivityFeedbackDataRepresentation>> {
    const response =
      await this.apiV1EwiStudentIdResultsActivityResultIdFeedbacksGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Finish later activity result. (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdFinishLaterPutRaw(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdFinishLaterPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdFinishLaterPut.',
      )
    }

    if (
      requestParameters.activityResultId === null ||
      requestParameters.activityResultId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityResultId',
        'Required parameter requestParameters.activityResultId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdFinishLaterPut.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/results/{activityResultId}/finish-later`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityResultId'}}`,
            encodeURIComponent(String(requestParameters.activityResultId)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ActivityResultViewModelToJSON(
          requestParameters.activityResultViewModel,
        ),
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Finish later activity result. (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdFinishLaterPut(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdFinishLaterPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.apiV1EwiStudentIdResultsActivityResultIdFinishLaterPutRaw(
      requestParameters,
      initOverrides,
    )
  }

  /**
   * Finish activity result. (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdFinishPutRaw(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdFinishPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UpdateActivityResultFinishRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdFinishPut.',
      )
    }

    if (
      requestParameters.activityResultId === null ||
      requestParameters.activityResultId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityResultId',
        'Required parameter requestParameters.activityResultId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdFinishPut.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/results/{activityResultId}/finish`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityResultId'}}`,
            encodeURIComponent(String(requestParameters.activityResultId)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ActivityResultViewModelToJSON(
          requestParameters.activityResultViewModel,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UpdateActivityResultFinishRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Finish activity result. (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdFinishPut(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdFinishPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UpdateActivityResultFinishRepresentation> {
    const response =
      await this.apiV1EwiStudentIdResultsActivityResultIdFinishPutRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Get an ActivityResult (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdGetRaw(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<ActivityResultDataRepresentationBaseApiResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdGet.',
      )
    }

    if (
      requestParameters.activityResultId === null ||
      requestParameters.activityResultId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityResultId',
        'Required parameter requestParameters.activityResultId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.includeActivity !== undefined) {
      queryParameters['includeActivity'] = requestParameters.includeActivity
    }

    if (requestParameters.includeAnswers !== undefined) {
      queryParameters['includeAnswers'] = requestParameters.includeAnswers
    }

    if (requestParameters.includeFeedbacks !== undefined) {
      queryParameters['includeFeedbacks'] = requestParameters.includeFeedbacks
    }

    if (requestParameters.includeUsers !== undefined) {
      queryParameters['includeUsers'] = requestParameters.includeUsers
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/results/{activityResultId}`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityResultId'}}`,
            encodeURIComponent(String(requestParameters.activityResultId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActivityResultDataRepresentationBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   * Get an ActivityResult (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdGet(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ActivityResultDataRepresentationBaseApiResult> {
    const response = await this.apiV1EwiStudentIdResultsActivityResultIdGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Update activity result to drafting (from submitted/revising) (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdRevisePutRaw(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdRevisePutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdRevisePut.',
      )
    }

    if (
      requestParameters.activityResultId === null ||
      requestParameters.activityResultId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityResultId',
        'Required parameter requestParameters.activityResultId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdRevisePut.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/results/{activityResultId}/revise`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityResultId'}}`,
            encodeURIComponent(String(requestParameters.activityResultId)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Update activity result to drafting (from submitted/revising) (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdRevisePut(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdRevisePutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.apiV1EwiStudentIdResultsActivityResultIdRevisePutRaw(
      requestParameters,
      initOverrides,
    )
  }

  /**
   * Create a ActivityResultSnapshot Feedback (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdFeedbacksPostRaw(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdFeedbacksPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdFeedbacksPost.',
      )
    }

    if (
      requestParameters.activityResultId === null ||
      requestParameters.activityResultId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityResultId',
        'Required parameter requestParameters.activityResultId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdFeedbacksPost.',
      )
    }

    if (
      requestParameters.activityResultSnapshotId === null ||
      requestParameters.activityResultSnapshotId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityResultSnapshotId',
        'Required parameter requestParameters.activityResultSnapshotId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdFeedbacksPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/results/{activityResultId}/snapshots/{activityResultSnapshotId}/feedbacks`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityResultId'}}`,
            encodeURIComponent(String(requestParameters.activityResultId)),
          )
          .replace(
            `{${'activityResultSnapshotId'}}`,
            encodeURIComponent(
              String(requestParameters.activityResultSnapshotId),
            ),
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateActivityFeedbackViewModelToJSON(
          requestParameters.createActivityFeedbackViewModel,
        ),
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Create a ActivityResultSnapshot Feedback (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdFeedbacksPost(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdFeedbacksPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdFeedbacksPostRaw(
      requestParameters,
      initOverrides,
    )
  }

  /**
   * Submit an Activity Result Snapshot (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdSubmitPutRaw(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdSubmitPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SubmissionCoinsRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdSubmitPut.',
      )
    }

    if (
      requestParameters.activityResultId === null ||
      requestParameters.activityResultId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityResultId',
        'Required parameter requestParameters.activityResultId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdSubmitPut.',
      )
    }

    if (
      requestParameters.activityResultSnapshotId === null ||
      requestParameters.activityResultSnapshotId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityResultSnapshotId',
        'Required parameter requestParameters.activityResultSnapshotId was null or undefined when calling apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdSubmitPut.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.reflection !== undefined) {
      queryParameters['reflection'] = requestParameters.reflection
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/results/{activityResultId}/snapshots/{activityResultSnapshotId}/submit`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityResultId'}}`,
            encodeURIComponent(String(requestParameters.activityResultId)),
          )
          .replace(
            `{${'activityResultSnapshotId'}}`,
            encodeURIComponent(
              String(requestParameters.activityResultSnapshotId),
            ),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SubmissionCoinsRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Submit an Activity Result Snapshot (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdSubmitPut(
    requestParameters: ApiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdSubmitPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SubmissionCoinsRepresentation> {
    const response =
      await this.apiV1EwiStudentIdResultsActivityResultIdSnapshotsActivityResultSnapshotIdSubmitPutRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Get User Activity Results (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsGetRaw(
    requestParameters: ApiV1EwiStudentIdResultsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<ActivityResultDataRepresentationBaseApiListResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdResultsGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.activityId !== undefined) {
      queryParameters['activityId'] = requestParameters.activityId
    }

    if (requestParameters.activityIds) {
      queryParameters['activityIds'] = requestParameters.activityIds
    }

    if (requestParameters.assignmentId !== undefined) {
      queryParameters['assignmentId'] = requestParameters.assignmentId
    }

    if (requestParameters.assignmentTaskId !== undefined) {
      queryParameters['assignmentTaskId'] = requestParameters.assignmentTaskId
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status
    }

    if (requestParameters.grade !== undefined) {
      queryParameters['grade'] = requestParameters.grade
    }

    if (requestParameters.fromDate !== undefined) {
      queryParameters['fromDate'] = requestParameters.fromDate
    }

    if (requestParameters.toDate !== undefined) {
      queryParameters['toDate'] = requestParameters.toDate
    }

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    if (requestParameters.includeActivity !== undefined) {
      queryParameters['includeActivity'] = requestParameters.includeActivity
    }

    if (requestParameters.includeAnswers !== undefined) {
      queryParameters['includeAnswers'] = requestParameters.includeAnswers
    }

    if (requestParameters.includeFeedback !== undefined) {
      queryParameters['includeFeedback'] = requestParameters.includeFeedback
    }

    if (requestParameters.isQuest !== undefined) {
      queryParameters['isQuest'] = requestParameters.isQuest
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/results`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActivityResultDataRepresentationBaseApiListResultFromJSON(jsonValue),
    )
  }

  /**
   * Get User Activity Results (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsGet(
    requestParameters: ApiV1EwiStudentIdResultsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ActivityResultDataRepresentationBaseApiListResult> {
    const response = await this.apiV1EwiStudentIdResultsGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsPutRaw(
    requestParameters: ApiV1EwiStudentIdResultsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ActivityResultRepresentationBaseApiResult>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdResultsPut.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/results`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ActivityResultViewModelToJSON(
          requestParameters.activityResultViewModel,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActivityResultRepresentationBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsPut(
    requestParameters: ApiV1EwiStudentIdResultsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ActivityResultRepresentationBaseApiResult> {
    const response = await this.apiV1EwiStudentIdResultsPutRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Returns the User\'s Result Summary Totals and Reflection emoji counts (header of Student App) (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsSummaryGetRaw(
    requestParameters: ApiV1EwiStudentIdResultsSummaryGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<GetStudentResultTotalsRepresentationBaseApiResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdResultsSummaryGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/results_summary`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetStudentResultTotalsRepresentationBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   * Returns the User\'s Result Summary Totals and Reflection emoji counts (header of Student App) (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdResultsSummaryGet(
    requestParameters: ApiV1EwiStudentIdResultsSummaryGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetStudentResultTotalsRepresentationBaseApiResult> {
    const response = await this.apiV1EwiStudentIdResultsSummaryGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Get the ActivityResultSnapshot (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdSnapshotsActivityResultSnapshotIdGetRaw(
    requestParameters: ApiV1EwiStudentIdSnapshotsActivityResultSnapshotIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<ActivityResultSnapshotCompositeRepresentation>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdSnapshotsActivityResultSnapshotIdGet.',
      )
    }

    if (
      requestParameters.activityResultSnapshotId === null ||
      requestParameters.activityResultSnapshotId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityResultSnapshotId',
        'Required parameter requestParameters.activityResultSnapshotId was null or undefined when calling apiV1EwiStudentIdSnapshotsActivityResultSnapshotIdGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/snapshots/{activityResultSnapshotId}`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityResultSnapshotId'}}`,
            encodeURIComponent(
              String(requestParameters.activityResultSnapshotId),
            ),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActivityResultSnapshotCompositeRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Get the ActivityResultSnapshot (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdSnapshotsActivityResultSnapshotIdGet(
    requestParameters: ApiV1EwiStudentIdSnapshotsActivityResultSnapshotIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ActivityResultSnapshotCompositeRepresentation> {
    const response =
      await this.apiV1EwiStudentIdSnapshotsActivityResultSnapshotIdGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Get the latest ActivityResult snapshots (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdSubmittedSnapshotsGetRaw(
    requestParameters: ApiV1EwiStudentIdSubmittedSnapshotsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1EwiStudentIdSubmittedSnapshotsGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/ewi/{studentId}/submitted_snapshots`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResultFromJSON(
        jsonValue,
      ),
    )
  }

  /**
   * Get the latest ActivityResult snapshots (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1EwiStudentIdSubmittedSnapshotsGet(
    requestParameters: ApiV1EwiStudentIdSubmittedSnapshotsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LatestSubmittedSnapshotFeedbackRepresentationBaseApiListResult> {
    const response = await this.apiV1EwiStudentIdSubmittedSnapshotsGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }
}
